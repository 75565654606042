<template>
  <div class="col-md-12 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Entidades</h1>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group row">
              <form v-on:submit.prevent="searchItems({ key: keywords})">
                <div class="input-group">
                  <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-outline-primary">Buscar</button>
                  </div>
                </div>                
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="">
              <button v-on:click="$router.push({ name: 'CreateEntidad', params: {add: true} });" class="btn btn-primary"><i data-feather="plus-circle"></i>Crear Entidad</button>
            </div>
            <br>
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="list-doc" role="tabpanel" aria-labelledby="list-doc-list">
                <div class="table-responsive">
                  <table class="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <td></td>
                        <td>Nombre</td>
                        <td>Tipo Identificación</td>
                        <td>Número Identificación</td>
                        <td>Ciudad</td>
                        <td>Dirección</td>
                        <td>Teléfono</td>
                        <td>Correo electrónico</td>
                        <td class="text-center"><input type="checkbox" v-model="allChecked" v-on:click="checkAllItems"></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in items">
                        <td class="text-center">
                          <button v-on:click="editItem(item._id)" class="btn btn-primary">Editar</button>                        
                        </td>
                        <td v-on:click="editItem(item._id)" onmouseover="this.style.cursor='pointer'">{{ item.ent_nmbre }}</td>
                        <td>{{ item.tid_nmbre }}</td>
                        <td>{{ item.ent_numero }}</td>
                        <td>{{ item.ent_ciudad }}</td>
                        <td>{{ item.ent_direccion }}</td>
                        <td>{{ item.ent_telefono }}</td>
                        <td>{{ item.ent_email }}</td>
                        <td class="text-center"><input type="checkbox" v-bind:value="item._id" v-model="delItems"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade" id="list-doc-archivo" role="tabpanel" aria-labelledby="list-archivo-list">...</div>
            </div>
            <br>
            <div class="row text-center">
              <div class="col-6">
                <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
                  <i data-feather="delete"></i>Eliminar
                </button>
                <!--{{delItems}}-->
              </div>
              <div class="col-6">
                <button class="btn btn-danger" v-if="notused" v-on:click="deleteItemsNotUsed">
                  <i data-feather="delete"></i>Eliminar no usados
                </button>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalForm :visible="show" @close="show=false" :message="'Por favor espere mientras se consultan los datos..'" />
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import ModalForm from "../modal/ModalForm.vue";

export default {
  components: {
    //VIcon,
    ModalForm,
  },
  data() {
    return {
      items: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords: "",
      search: {},
      filter: false, // Items are filtered
      show: false,
      notused: false
    };
  },
  computed: {
    checkedItems() {
      if (this.delItems.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function() {
    feather.replace();
  },
  created: function() {
    let uri = '/tpousus/edit/' + String(this.$store.state.userdata.type);
    this.axios.get(uri)
    .then(response => {
      if (response.data.tus_cdgo >= 0 && response.data.tus_cdgo <= 1){
        this.notused = true;
      }

      this.fetchTpoid();

      if (this.$route.params.search) {
        this.search = this.$route.params.search;
        this.searchItems(this.search);
      }
    });
  },
  methods: {
    fetchTpoid(){
      //alert(String(this.$store.state.company));
      let uri = '/tpoids/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpoids = response.data;

        for (let i = 0; i < this.items.length; i++){
          for (let j = 0; j < this.tpoids.length; j++){
            if (this.items[i].tid_id == this.tpoids[j]._id){
              this.items[i].tid_nmbre = this.tpoids[j].tid_nmbre;
              j = this.tpoids.length;
            }
          }
        }
      });
    },
    deleteItems() {
      if (this.delItems.length > 0) {
        const response = confirm("Está seguro que desea eliminar los registros seleccionados?");
        if (response) {
          this.show = true;
          let uri = "/entidades/deleteitems";
          this.axios
            .post(uri, { cmp_id: String(this.$store.state.company), delItems: this.delItems })
            .then((res) => {
              this.show = false;
              if (this.search) {
                this.searchItems(this.search);
              }
            })
            .catch((err) => {
              this.show = false;
              alert(err.response.data.msg);
              if (err.response.data.i > 0) {
                if (this.search) {
                  this.searchItems(this.search);
                }
              }
            });
        }
      }
      return;
    },
    deleteItemsNotUsed() {
      const response = confirm("Está seguro que desea eliminar los registros no usados?");
      if (response) {
        this.show = true;
        let uri = "/entidades/deleteitemsnotused";
        this.axios
          .post(uri, { cmp_id: String(this.$store.state.company) }, { timeout: 0 })
          .then((res) => {
            this.show = false;
            alert(res.data.msg);
            this.items = [];
          })
          .catch((err) => {
            this.show = false;
            alert(err.response.data.msg);
            if (err.response.data.i > 0) {
              this.items = [];
            }
          });
      }
  
      return;
    },
    checkAllItems() {
      this.delItems = [];
      if (!this.allChecked) {
        for (var i = 0; i < this.items.length; i++) {
          this.delItems.push(this.items[i]._id);
        }
      }
    },
    searchItems(p){
      if (p != null){
        if (p.key){
          this.keywords = p.key;
        } else {
          this.keywords = '';
        }
        this.message = 'Buscando entidades';
        this.show = true;
        p.cmp_id = this.$store.state.company;
        let uri = '/entidades/search';
        this.axios.post(uri, p)
        .then(response => {
          this.items = response.data;
          this.search = p;
          this.delItems = [];
          this.filter = true;
          this.show = false;

          for (let i = 0; i < this.items.length; i++){
            for (let j = 0; j < this.tpoids.length; j++){
              if (this.items[i].tid_id == this.tpoids[j]._id){
                this.items[i].tid_nmbre = this.tpoids[j].tid_nmbre;
                j = this.tpoids.length;
              }
            }
          }
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar las entidades' + err;
        });
      }
    },
    closeSearch() {
      this.filter = false;
      this.search = {};
      this.keywords = "";
      this.items = [];
    },
    newSearch () {
      this.search = {};
    },
    createItem() {
      this.$router.push({ name: "CreateEntidad", params: { add: true, search: this.search } });
    },
    editItem(id) {
      this.$router.push({ name: "CreateEntidad", params: { id: id, add: false, search: this.search } });
    }
  },
};
</script>
